import PhoneIcon from '#public/icon/new-phone.svg'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'
import CSAvailability from './CSAvailability'

export default function CareSpecialist() {
  return (
    <div className="flex items-center space-x-4 text-neutral-800">
      <PhoneIcon className="w-8" />
      <a className="flex flex-col space-y-0" href={`tel:${COMPANY_INFO.phone.value}`}>
        <p className="font-bold text-neutral-800">
          Speak to a Care Specialist&nbsp;
          <CSAvailability />
        </p>
        <p className="text-[#260080] underline">{COMPANY_INFO.phone.displayValue}</p>
      </a>
    </div>
  )
}
