import { CwLogo } from '#public/icon/logo/CwLogo'
import CareSpecialist from './CareSpecialist'
import ChatWidget from './ChatWidget'
import { DesktopMenuSection } from './DesktopMenuSection'
import FollowUsSection from './FollowUsSection'
import FooterSubscribe from './FooterSubscribe'
import JoinOurTeam from './JoinOurTeam'
import LegalSection from './LegalSection'
import MobileComplianceSection from './MobileComplianceSection'
import MobileHelpSection from './MobileHelpSection'
import MobileMenuSection from './MobileMenuSection'
import SectionDivider from './SectionDivider'

export default function Footer() {
  return (
    <footer className="bg-white text-neutral-800 px-6 pb-28 pt-14 print:hidden sm:px-14 md:mt-20 lg:pb-4 lg:pt-0">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="hidden space-x-32 pb-10 pt-12 lg:flex">
          <ChatWidget />
          <CareSpecialist />
          <JoinOurTeam />
        </div>
        <SectionDivider className="mb-10 hidden lg:block" />
        <div className="grid text-base md:grid-cols-11 md:gap-4">
          <div className="col-span-12 space-y-6 lg:col-span-4">
            <div className="relative h-9 w-40">
              <CwLogo className="h-6 w-28 lg:h-[30px] lg:w-36" />
            </div>
            <FooterSubscribe />
          </div>
          <MobileHelpSection />
          <DesktopMenuSection />
        </div>
        <MobileMenuSection />
        <SectionDivider className="mb-10 lg:hidden" />
        <div className="grid grid-cols-11 lg:mt-48">
          <LegalSection className="col-span-6 hidden flex-nowrap items-center space-x-4 space-y-0 lg:flex" />
          <FollowUsSection className="col-span-5 hidden items-end justify-evenly space-x-4 lg:flex" />
          <MobileComplianceSection />
        </div>
      </div>
    </footer>
  )
}
