import CarewellIcon from '#public/icon/new-carewell.svg'
import Link from 'next/link'
import routes from '../../../../lib/routes'

export default function JoinOurTeam() {
  return (
    <div className="flex items-center space-x-4 text-neutral-800">
      <CarewellIcon className="w-8" />
      <Link href={routes.careers()} className="flex cursor-pointer flex-col">
        <span className="font-bold text-neutral-800">Join Our Team</span>
        <span className="text-[#260080] underline">Check out careers</span>
      </Link>
    </div>
  )
}
