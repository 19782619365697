import MessageIcon from '#public/icon/new-message.svg'
import { useKustomerChatStatus } from 'components/global/kustomer-chat-context'

export default function CSChatWiget() {
  const { initialized, openChat, statusText, unavailable } = useKustomerChatStatus()
  const disabled = !initialized || unavailable

  return (
    <div className="flex items-center space-x-4 text-neutral-800">
      <MessageIcon className="w-8" />
      <button
        type="button"
        disabled={!initialized}
        className={`flex flex-col text-left ${disabled && 'cursor-not-allowed'}`}
        onClick={openChat}
      >
        <p className="font-bold text-neutral-800">Live Care Advice</p>
        <p className="text-[#260080] underline">{statusText}</p>
      </button>
    </div>
  )
}
