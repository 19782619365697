import clsx from 'clsx'
import Link from 'next/link'
import { useRef } from 'react'
import useAuthStateQuery from '../../../hooks/useAuthStateQuery'
import { usePreventBackgroundScrollOnModal } from '../../../hooks/usePreventBackgroundScrollOnModal'
import { HEADER_HEIGHT } from '../../../lib/constants/HEADER_HEIGHT'
import featureFlags from '../../../lib/featureFlags'
import routes from '../../../lib/routes'
import type {
  BigCommerceGQLCategoryTree3LevelsDeep,
  BigCommerceGQLCategoryTreeItem,
} from '../../../lib/serverOnly/api/bigcommerce/graphql/catalog'
import { ChevronIcon } from '../icons/ChevronRightIcon'

function MenuLink({
  page,
  className = '',
  onClick,
}: {
  page: BigCommerceGQLCategoryTreeItem
  className?: string
  onClick?: () => void
}) {
  return (
    <Link
      href={page?.path || ''}
      prefetch={false}
      role="button"
      className={`inline-block py-2 pr-5 ${className}`}
      onClick={onClick}
      tabIndex={0}
      onKeyPress={(e) => {
        // TODO: onClick can be potentially be replaced with https://github.com/carewell/carewell-headless/issues/21
        if (e.key === 'Enter') {
          onClick?.()
        }
      }}
    >
      {page?.name}
    </Link>
  )
}

function MenuItem({
  className,
  depth,
  onClick,
  page,
}: {
  page: BigCommerceGQLCategoryTreeItem
  className: string
  depth: number
  onClick?: () => void
}) {
  return (
    <li className="mx-5">
      {Array.isArray(page?.children) && page?.children?.length > 0 ? (
        <details>
          <summary className={className}>
            {depth === 0 || page?.children?.length > 0 ? (
              <div
                className={clsx('flex w-full flex-row text-left', depth === 0 ? 'py-4' : 'py-2')}
              >
                <div className="flex-1 pr-4">{page?.name}</div>
                <ChevronIcon className="relative top-2 h-4 w-4 rotate-90 transform" />
              </div>
            ) : (
              <MenuLink page={page} onClick={onClick} />
            )}
          </summary>

          <div className="mb-4 rounded-xl bg-purple-25 py-2">
            <MenuLink
              page={{ ...page, name: `Shop all` }}
              className="px-5 font-bold text-purple-600"
              onClick={onClick}
            />

            <Menu
              pages={page?.children}
              className="font-medium"
              onClick={onClick}
              depth={depth + 1}
            />
          </div>
        </details>
      ) : (
        <MenuLink
          className={clsx('font-bold', depth === 0 && 'mt-2')}
          page={page}
          onClick={onClick}
        />
      )}
    </li>
  )
}

function Menu({
  pages,
  className = '',
  onClick,
  depth = 0,
}: {
  pages: BigCommerceGQLCategoryTree3LevelsDeep
  className?: string
  onClick?: () => void
  depth?: number
}) {
  return (
    <ul className={clsx(depth === 0 && 'divide-y divide-[#464D4D] divide-opacity-30')}>
      {pages.map((page) => (
        <MenuItem
          key={page?.entityId}
          page={page}
          className={className}
          depth={depth}
          onClick={onClick}
        />
      ))}
    </ul>
  )
}

export default function NavMobile({
  categories = [],
  onClick,
  className,
  open,
}: {
  categories?: BigCommerceGQLCategoryTree3LevelsDeep
  onClick?: () => void
  className?: string
  open: boolean
}) {
  const { data: customer } = useAuthStateQuery()
  const ref = useRef<HTMLElement>(null)
  usePreventBackgroundScrollOnModal(open)

  return (
    <nav
      ref={ref}
      data-qa="nav-mobile"
      className={clsx(
        'h-full-native fixed z-30 w-screen overflow-auto overscroll-contain bg-white text-xl font-bold text-[#464D4D]',
        open ? '' : 'hidden',
        className
      )}
      style={{
        paddingTop: HEADER_HEIGHT,
        top: 0,
      }}
    >
      <Menu pages={categories} onClick={onClick} />
      <ul className="mx-5 my-4 border-t pt-4 border-t-[#464D4D] border-opacity-30">
        {[
          featureFlags.healthpilot && { name: 'Enroll in Medicare', path: routes.medicare() },
          { name: 'Brands', path: routes.featuredBrands() },
          { name: 'Order Lookup', path: customer ? routes.account.orders() : routes.orderLookup() },
          { name: 'Deals', path: routes.deals() },
          { name: 'Learn', path: routes.resources() },
          { name: 'Careers', path: routes.careers() },
          { name: 'Newsroom', path: routes.newsroom() },
        ].map((page, idx) => {
          if (!page) return null

          return (
            <li key={idx}>
              <MenuLink page={page} onClick={onClick} />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
