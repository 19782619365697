import { SVGProps } from 'react'

export function CwLogo({
  includeRegistered = true,
  includeIcon = true,
  color,
  ...props
}: SVGProps<SVGSVGElement> & {
  includeRegistered?: boolean
  includeIcon?: boolean
}) {
  const box = [0, 0, 189, 40] as const
  let [x, y, width, height] = [...box]

  if (!includeRegistered) {
    width -= 10
  }
  if (!includeIcon) {
    width -= 32
  }

  return (
    <svg
      viewBox={`${x} ${y} ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M14.6985 37.515C7.6776 37.515 2.66729 33.3476 1.2998 32.07L4.56747 28.6025C5.61176 29.5776 9.43161 32.7619 14.6985 32.7619C19.9654 32.7619 23.7853 29.5792 24.8296 28.6025L28.0972 32.07C26.7298 33.3476 21.7195 37.515 14.6985 37.515Z"
        fill={color || '#9E8FFF'}
      />
      <path
        d="M25.4255 16.8113C25.1543 19.4598 22.7051 23.1493 14.6968 28.9612C6.69 23.1493 4.23925 19.4614 3.97128 16.8113C3.57338 12.9078 6.75009 10.0598 10.3718 10.5345C13.1717 10.9029 14.6968 13.5144 14.6968 13.5144C14.6968 13.5144 16.2218 10.9029 19.0233 10.5345C22.6467 10.0582 25.8218 12.9062 25.4255 16.8113Z"
        fill={color || '#3B15AD'}
      />
      <path
        d="M32.6143 24.5335C32.6143 17.0289 38.1475 11.5518 45.477 11.5518C50.5799 11.5518 54.2796 14.0763 56.2561 17.1705L52.6993 19.8737C51.1548 17.4907 48.6749 15.9975 45.5128 15.9975C40.8776 15.9975 37.3209 19.5905 37.3209 24.5335C37.3209 29.4765 40.8776 33.0694 45.5128 33.0694C48.6749 33.0694 51.1532 31.6117 52.6993 29.1933L56.2561 31.8965C54.2439 35.026 50.5426 37.5152 45.477 37.5152C38.1475 37.5152 32.6143 32.0381 32.6143 24.5335Z"
        fill={color || '#130040'}
      />
      <path
        d="M79.196 19.5538H83.6882V22.1154C84.981 20.0526 86.886 19.1982 89.3644 19.1982C89.6161 19.1982 89.759 19.1982 90.0465 19.2336V23.6794C89.5788 23.6086 89.2198 23.6086 88.8609 23.6086C85.9148 23.6086 83.6866 25.7422 83.6866 29.5122V37.1583H79.1943V19.5538H79.196Z"
        fill={color || '#130040'}
      />
      <path
        d="M91.1709 28.3746C91.1709 23.0036 95.0151 19.1982 100.297 19.1982C105.184 19.1982 109.135 22.3986 109.135 28.0544C109.135 28.7302 109.063 29.3352 108.956 29.9402H95.806C96.4167 32.3232 98.3932 33.8164 100.729 33.8164C102.848 33.8164 104.358 32.785 105.257 31.6104L107.987 34.2428C106.333 36.341 103.819 37.5155 100.657 37.5155C95.4114 37.5155 91.1709 33.995 91.1709 28.3746ZM104.716 26.7736C104.248 24.3552 102.523 22.8974 100.297 22.8974C97.9255 22.8974 96.2364 24.3906 95.733 26.7736H104.716Z"
        fill={color || '#130040'}
      />
      <path
        d="M109.71 19.5537H114.417L117.579 32.2152L121.028 19.5537H125.124L128.538 32.2152L131.7 19.5537H136.335L131.125 37.1598H126.167L123.04 26.0993L119.914 37.1598H114.956L109.71 19.5537Z"
        fill={color || '#130040'}
      />
      <path
        d="M136.943 28.3746C136.943 23.0036 140.788 19.1982 146.069 19.1982C150.956 19.1982 154.907 22.3986 154.907 28.0544C154.907 28.7302 154.836 29.3352 154.729 29.9402H141.579C142.189 32.3232 144.166 33.8164 146.501 33.8164C148.621 33.8164 150.131 32.785 151.029 31.6104L153.759 34.2428C152.106 36.341 149.592 37.5155 146.43 37.5155C141.184 37.5155 136.943 33.995 136.943 28.3746ZM150.488 26.7736C150.021 24.3552 148.296 22.8974 146.069 22.8974C143.698 22.8974 142.009 24.3906 141.505 26.7736H150.488Z"
        fill={color || '#130040'}
      />
      <path
        d="M166.244 37.1323C165.437 37.3946 164.559 37.5153 163.729 37.5153C160.172 37.5153 158.124 35.1677 158.124 31.4685V10.4834H162.616V30.9343C162.616 32.7477 163.586 33.5313 164.844 33.5313C165.312 33.5313 165.887 33.4251 166.246 33.2819V37.1307L166.244 37.1323Z"
        fill={color || '#130040'}
      />
      <path
        d="M176.7 37.1323C175.892 37.3946 175.014 37.5153 174.184 37.5153C170.627 37.5153 168.579 35.1677 168.579 31.4685V10.4834H173.071V30.9343C173.071 32.7477 174.041 33.5313 175.3 33.5313C175.767 33.5313 176.342 33.4251 176.701 33.2819V37.1307L176.7 37.1323Z"
        fill={color || '#130040'}
      />
      <path
        d="M74.3853 31.2046C74.3853 28.0782 74.5412 25.0049 73.8136 23.021C72.9057 20.5463 70.5021 19.1963 66.8057 19.1963C62.8348 19.1963 59.2926 21.5037 59.2926 21.5037L61.248 24.667C61.248 24.667 63.5234 23.4248 65.1605 23.1851C66.7975 22.9454 69.9726 22.7667 69.9726 26.5029C69.9726 26.5029 65.3472 26.2214 62.6318 26.7845C59.9163 27.3477 57.9235 29.1289 58.0876 32.2681C58.2532 35.4074 60.9021 37.5136 64.4394 37.5136C67.1565 37.5136 69.284 36.479 70.4111 34.8716C70.4111 34.8716 70.9308 36.87 73.0584 37.364C74.9553 37.8032 76.7987 37.1435 76.7987 37.1435V33.2899C76.7987 33.2899 74.3853 34.7283 74.3853 31.2046ZM65.4837 34.0011C63.0085 34.0011 62.2355 32.4033 62.6204 31.1032C63.0053 29.8031 64.3614 29.5022 65.6769 29.5022H69.971C69.971 32.5063 67.9588 34.0011 65.4837 34.0011Z"
        fill={color || '#130040'}
      />
      {includeRegistered && (
        <path
          d="M184.363 37.4257C183.829 37.4257 183.322 37.3246 182.841 37.1223C182.361 36.9201 181.941 36.6408 181.582 36.2844C181.222 35.9281 180.941 35.5107 180.737 35.034C180.534 34.5572 180.432 34.0548 180.432 33.5235C180.432 32.9922 180.534 32.464 180.737 31.9921C180.941 31.5202 181.222 31.106 181.582 30.7497C181.941 30.3933 182.361 30.114 182.841 29.9118C183.322 29.7095 183.828 29.6084 184.363 29.6084C184.898 29.6084 185.431 29.7095 185.906 29.9118C186.382 30.114 186.799 30.3933 187.158 30.7497C187.517 31.106 187.798 31.5202 188.002 31.9921C188.206 32.464 188.308 32.9745 188.308 33.5235C188.308 34.0724 188.206 34.5572 188.002 35.034C187.798 35.5107 187.517 35.9281 187.158 36.2844C186.799 36.6408 186.382 36.9201 185.906 37.1223C185.431 37.3246 184.916 37.4257 184.363 37.4257ZM184.363 36.6167C184.975 36.6167 185.513 36.4867 185.979 36.2266C186.445 35.9666 186.808 35.6038 187.07 35.1367C187.332 34.6696 187.463 34.1334 187.463 33.5251C187.463 32.9167 187.332 32.3693 187.07 31.907C186.808 31.4447 186.445 31.082 185.979 30.8171C185.513 30.5523 184.975 30.4206 184.363 30.4206C183.752 30.4206 183.228 30.5539 182.762 30.8171C182.296 31.082 181.932 31.4463 181.67 31.907C181.408 32.3677 181.277 32.9087 181.277 33.5251C181.277 34.1415 181.408 34.6696 181.67 35.1367C181.932 35.6038 182.296 35.9666 182.762 36.2266C183.228 36.4867 183.762 36.6167 184.363 36.6167ZM182.893 35.5749V31.3565H184.421C184.858 31.3565 185.217 31.4801 185.499 31.724C185.78 31.9696 185.921 32.2955 185.921 32.7C185.921 32.9697 185.845 33.2105 185.694 33.4223C185.544 33.6342 185.343 33.7931 185.091 33.8991L186.095 35.5749H185.237L184.363 34.0436H183.65V35.5749H182.893ZM183.65 33.3501H184.451C184.645 33.3501 184.808 33.2907 184.937 33.1703C185.068 33.0499 185.133 32.8942 185.133 32.7016C185.133 32.509 185.065 32.3388 184.929 32.2184C184.793 32.0981 184.614 32.0387 184.391 32.0387H183.648V33.3533L183.65 33.3501Z"
          fill={color || '#130040'}
        />
      )}
    </svg>
  )
}
