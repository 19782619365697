import useCustomerHasAutoshipSubscriptions from '../../../hooks/useCustomerHasAutoshipSubscriptions'
import AUTOSHIP from '../../../lib/constants/AUTOSHIP'
import SHIPPING_METHOD from '../../../lib/constants/SHIPPING_METHOD'
import { featureFlags } from '../../../lib/publicConfig'
import AB from '../ab-testing/AB'
import { useUI } from '../ui-context'

export function SiteBanner() {
  const { openAutoshipModal, openHolidayShippingModal } = useUI()
  const customerHasAutoshipSubscriptions = useCustomerHasAutoshipSubscriptions()

  return (
    <header className="flex h-[2.85rem] items-center justify-center overflow-hidden bg-banner text-center text-base text-white sm:h-[2.625rem] md:h-[2.5rem] md:text-[1.375rem]">
      {featureFlags.displayPromoAlert ? (
        <p className="p-1 text-sm leading-snug md:text-xl">
          New Year, New Savings. Save 15% Off Sitewide with Code: <strong>NY2023</strong>. Limited
          time only!
        </p>
      ) : featureFlags.displayShippingDelayAlert ? (
        <button type="button" className="w-full space-x-2 p-1" onClick={openHolidayShippingModal}>
          <span className="font-semibold">Holiday shipping may impact delivery times.</span>
          <span className="whitespace-nowrap text-sm underline md:text-lg">Learn More</span>
        </button>
      ) : !customerHasAutoshipSubscriptions ? (
        <>
          <AB name="autoship-banner-language" value="a">
            <button type="button" className="w-full p-1" onClick={openAutoshipModal}>
              <span className="font-bold">
                Save {AUTOSHIP.promotions.first.discount.percentage}%
              </span>
              <span>&nbsp;on your first</span>
              <span className="font-semibold">&nbsp;Autoship* </span>
              <span className="whitespace-nowrap text-sm underline md:text-lg">Learn More</span>
            </button>
          </AB>
          <AB name="autoship-banner-language" value="b">
            <button type="button" className="w-full p-1" onClick={openAutoshipModal}>
              <span>Join thousands of others on</span>
              <span className="font-semibold">&nbsp;Autoship* </span>
              <span className="whitespace-nowrap text-sm underline md:text-lg">Learn More</span>
            </button>
          </AB>
        </>
      ) : (
        <p className="p-1">
          <span className="font-bold">{SHIPPING_METHOD.promotional.label} Shipping</span>
          &nbsp;on Orders ${SHIPPING_METHOD.promotional.threshold}&#43;
        </p>
      )}
    </header>
  )
}
