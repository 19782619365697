import clsx from 'clsx'
import type { FormEventHandler } from 'react'
import React, { useRef, useState } from 'react'
import type { SearchSpringSuggestData } from '../../../hooks/pageType/category/useSearchspringSuggest'
import { useClickAway } from '../../../hooks/useClickAway'
import type { UseHeaderSearchSpringQuery } from '../../../hooks/useHeaderSearchSpringQuery'
import LegacySearchIcon from '../../../public/icon/legacy-search-icon.svg'
import { getSuggestionElem, SuggestionsBox } from './SuggestionsBox'

export type SearchFormProps = {
  onChange: (value: string) => void
  value: string | undefined
  className?: string
  suggestData: SearchSpringSuggestData | undefined | null
  filter: [string, string] | null
  onFilterChange: (filter: [string, string] | null) => void
  searchData?: UseHeaderSearchSpringQuery | null
  selectedSuggestion: string | undefined
  onSelectedSuggestionChange: (selectedSuggestion?: string) => void
  displaySearchSuggestions: boolean
  onOpenSearchMenu: () => void
  onCloseSearchMenu: () => void
  onOpenSearchSuggestions: () => void
  onCloseSearchSuggestions: () => void
  onSubmit: FormEventHandler
  id: string
}

// The placeholder needs to be an aria-hidden element due to the use of text formatting that isn't supported by the usual placeholder attribute
function Placeholder() {
  return (
    <div
      className="pointer-events-none absolute left-0 top-0 z-0 flex h-full w-full items-center overflow-hidden py-2.5 pl-4 pr-9 text-sm text-[#2B2B4A] text-opacity-80"
      aria-hidden={true}
    >
      <p className="line-clamp-1">
        Search <strong>products</strong> and <strong>brands</strong> here...
      </p>
    </div>
  )
}

export default function SearchForm({
  onChange,
  value,
  className,
  suggestData,
  filter,
  onFilterChange,
  searchData,
  selectedSuggestion,
  onSelectedSuggestionChange,
  displaySearchSuggestions,
  onOpenSearchMenu,
  onOpenSearchSuggestions,
  onCloseSearchSuggestions,
  onCloseSearchMenu,
  onSubmit,
  id,
}: SearchFormProps) {
  const [isFocused, setIsFocused] = useState(false)
  const hasValue = !!value
  const formRef = useRef<HTMLFormElement | null>(null)

  useClickAway(formRef, displaySearchSuggestions, onCloseSearchSuggestions)

  const inputRef = useRef<HTMLInputElement | null>(null)

  return (
    <form
      ref={formRef}
      role="search"
      autoComplete="off"
      onSubmit={onSubmit}
      className={clsx('text-coolGray lg:relative', className)}
    >
      <label className="visually-hidden" htmlFor={id}>
        Search products and brands here...
      </label>
      <div className="relative flex flex-1 flex-row items-stretch">
        {!isFocused && !hasValue && <Placeholder />}
        <input
          ref={inputRef}
          className={clsx(
            'no-webkit-appearance hide-clear bg-white flex-1 rounded-[67px] py-3.5 px-4 text-base leading-none focus:outline-none border border-[#E2E2E5]',
            // Make space for clear button
            hasValue && 'pr-24'
          )}
          autoComplete="off"
          autoCapitalize="none"
          id={id}
          type="search"
          aria-placeholder={'Search products and brands here...'}
          onFocus={(e) => {
            onOpenSearchMenu()
            setIsFocused(true)
            if (e.target.value) {
              onOpenSearchSuggestions()
            }
          }}
          onChange={(e) => {
            onChange(e.target.value)
            if (e.target.value) {
              onOpenSearchMenu()
              onOpenSearchSuggestions()
            } else {
              onCloseSearchSuggestions()
            }
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          onKeyDown={(e) => {
            if (e.key === 'ArrowDown') {
              const elem = getSuggestionElem(2)
              if (elem) {
                e.preventDefault()
                const text = elem.textContent ?? undefined
                onSelectedSuggestionChange(text)
                elem.focus()
              }
            }
          }}
          value={value}
        />

        {hasValue && (
          <button
            type="button"
            className="absolute right-16 top-0 h-full text-sm font-semibold underline"
            onClick={() => {
              onChange('')
              inputRef.current?.focus()
            }}
          >
            Clear
          </button>
        )}

        <button
          type="submit"
          title="Search"
          className="absolute right-1 top-1 flex h-[43px] w-[43px] items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2"
        >
          <LegacySearchIcon className="h-5 w-5 pb-1 text-[#260080]" />
        </button>
      </div>

      {suggestData && displaySearchSuggestions && (
        <SuggestionsBox
          selected={selectedSuggestion}
          onSelectedChange={onSelectedSuggestionChange}
          query={value ?? ''}
          suggestData={suggestData}
          show={displaySearchSuggestions}
          onClickSuggestion={() => {
            onChange('')
            onCloseSearchMenu()
            onCloseSearchSuggestions()
          }}
          filter={filter}
          onFilterChange={onFilterChange}
          searchData={searchData}
        />
      )}
    </form>
  )
}
