import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { HEADER_HEIGHT } from '../../../../../lib/constants/HEADER_HEIGHT'
import { trpc } from '../../../../../lib/utils/trpc'
import { CwLogo } from '../../../../../public/icon/logo/CwLogo'
import MenuButton from '../../MenuButton'
import SearchForm from '../../SearchForm'
import type SharedHeaderProps from '../SharedHeaderProps'
import MobileAccountButton from './MobileAccountButton'

const CartLink = dynamic(() => import('../../CartLink'), { ssr: false })

export type MobileHeaderProps = {
  menuOpen?: boolean
  onMenuClick?: () => void
} & Omit<SharedHeaderProps, 'showHelpMenu' | 'onShowHelpMenuChange'>

const HeaderContainer = forwardRef<HTMLElement, { className?: string; children?: ReactNode }>(
  ({ className, children }, ref) => {
    return (
      <header
        ref={ref}
        className={clsx('z-40 w-full bg-white print:hidden border-b border-[#E2E1E5]', className)}
        style={{ height: HEADER_HEIGHT }}
      >
        <div className="px-4 py-2">{children}</div>
      </header>
    )
  }
)

const MobileHeader = forwardRef<HTMLElement, MobileHeaderProps>(
  (
    {
      menuOpen = false,
      onMenuClick,
      cartData,
      searchQuery,
      onSearchQueryChange,
      suggestData,
      filter,
      onFilterChange,
      searchData,
      selectedSuggestion,
      onSelectedSuggestionChange,
      displaySearchSuggestions,
      onOpenSearchMenu,
      onCloseSearchMenu,
      onOpenSearchSuggestions,
      onCloseSearchSuggestions,
      onSubmit,
      className,
    },
    ref
  ) => {
    const { data: customer } = trpc.auth.state.useQuery()

    return (
      <HeaderContainer ref={ref} className={className}>
        <div className="flex">
          <div className="mr-4 flex-1">
            <Link href="/" prefetch={false} className="relative block h-6 w-32">
              <CwLogo includeRegistered={false} className="h-6 w-32" />
            </Link>
          </div>
          <div className="ml-2 flex items-center space-x-4">
            <div className="relative flex items-center text-neutral-800">
              <MobileAccountButton loggedIn={!!customer} />
            </div>
            <CartLink quantity={cartData?.count} />
          </div>
        </div>

        <div className="flex w-full flex-1 space-x-2 pt-2.5">
          <MenuButton open={menuOpen} onClick={onMenuClick} className="text-neutral-800" />
          <div className="flex-1">
            <SearchForm
              value={searchQuery}
              onChange={(value) => {
                onSearchQueryChange?.(value)
              }}
              suggestData={suggestData}
              filter={filter}
              onFilterChange={onFilterChange}
              searchData={searchData}
              selectedSuggestion={selectedSuggestion}
              onSelectedSuggestionChange={onSelectedSuggestionChange}
              displaySearchSuggestions={displaySearchSuggestions}
              onCloseSearchMenu={onCloseSearchMenu}
              onOpenSearchMenu={onOpenSearchMenu}
              onOpenSearchSuggestions={onOpenSearchSuggestions}
              onCloseSearchSuggestions={onCloseSearchSuggestions}
              onSubmit={onSubmit}
              id="search-products-input-mobile"
            />
          </div>
        </div>
      </HeaderContainer>
    )
  }
)

export default MobileHeader
